import React from 'react'
import logo from '../assets/lsl-logo.svg'
import background from '../assets/BG.png'

export const Home = () => {

  let today = new Date();
  let year = today.getFullYear()

  return (
    <div className="home-main">
        <div className="home-inner">
          <img className="logo-lsl"src={logo} alt="LSL" />
          <div>
            <h1>Welcome To Loyalty Solutions Limited Appointment</h1>
            <p>We Would Love To Hear From You</p>
          </div>
        </div>
        <div>
          <img className="bg-image"src={background} alt="BACKGROUND" />
        </div>
        <p id='footer'>Copyright {year}</p>
    </div>
  )
}

