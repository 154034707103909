
import React, { useContext } from 'react';
import 'react-calendar/dist/Calendar.css';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { DateAndTime } from '../App';
import 'react-day-picker/lib/style.css';


const MyCalender = () => {

  const { selectedDate, onChange, avail_date} = useContext(DateAndTime);

  return (
    <div className="calender"> 
    <h5>{ selectedDate ?  "Click to change date" : "Select a Date" }</h5>
    <div>
      <Flatpickr
        value={selectedDate}
        onChange={onChange}
        options={{ 
          position: 'center',
          enable: avail_date,
          onReady (_, __, fp) { 
            fp.calendarContainer.classList.add("open");
          },
        }}
      />
    </div>
  </div>
  );
}

export default MyCalender