import React, { useState, useEffect} from 'react'
import MyCalender from './components/Calender'
import Logo from './components/Logo'
import './components/style.scss'
import Time from './components/Time'
import UserInformation from './components/UserInformation'
import axios from 'axios'
import "flatpickr/dist/themes/material_green.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {Home} from './components/home'


export const DateAndTime = React.createContext();


function App() {

  const [ isLoading, setLoading ] = useState(true)
  const [ slug, setSlug ] = useState('')

  const [ selectedDate, onChange ] = useState(  )
  const [ avail_date, setAvail_date ] = useState([])
  const [ timesAvailable, setAvailableTime ] = useState([])
  const [ arrTime, setArrTime ] = useState([])
  const [ active, setaActive ] = useState(false)

  const [ selectedTime, setSelectedTime ] = useState([])

  const slot_arr = selectedTime.map( id => id.id)
  const slot_id = slot_arr[0]

  const pathname = window.location.pathname

  console.log('selected date', selectedDate)

  useEffect( () => {

    axios.get(`https://meeting.perxclm.com/contact${pathname}/details`)
      .then(res => {
        console.log(res)
        if( res.status === 200 ){
          setSlug(res.data.data.slug)
          setLoading(false);  
        }
      }).catch( err => {
          setLoading(false)
      })
  }, [])



  const getAvailableDate = () => {

    axios.get(`https://meeting.perxclm.com/contact${pathname}/appointments`)
    .then( res => {
      const meetingInfo = res.data.message
      const availableTime = meetingInfo.map( t => t.times)
      const time = availableTime.map( t => (t))
      setAvailableTime(time)
      const availableDate = meetingInfo.map( d => (d.avail_date))
      setAvail_date(availableDate)
      setaActive(true)
    })
  } 

  

  useEffect( () => {
    getAvailableDate()
    
  },[])

  let event = new Date(selectedDate);
  event.setMinutes(event.getMinutes()-event.getTimezoneOffset())
  let selected = JSON.stringify(event)
  selected = selected.slice(1,11)

  let indexOfSelectedDate = avail_date.indexOf(selected)
  let times = timesAvailable[indexOfSelectedDate]

  let today = new Date();
  let year = today.getFullYear() 

  if( slug ){
    return (
      <DateAndTime.Provider value={{selectedDate, onChange, avail_date, times, arrTime, selectedTime, slot_id, isLoading, active, setLoading, setSelectedTime, pathname}}>
        <div className="containers">
          <div className="containers-inner">
            <Logo/>
            <div className="containers-lg">
              <div className="containers-inner-1">
                <div>
                  <MyCalender/>
                </div>
                <div id='test'>
                  <a href="#info">
                    <Time/>
                  </a>
                </div>
              </div>
              <div className="containers-inner-2" id="info" >
                <UserInformation/>
              </div>
            </div>
          </div>
          <p id='footer'>Copyright {year}</p>
        </div>
      </DateAndTime.Provider>
    );
  } else {
    return(
      <div>
        { 
          isLoading 
          ?
            <div style={{textAlign: 'center'}}>
              <Logo/>
              <Loader type="Circles" color="#0098CE" height={80} width={80} /> 
            </div>
          :
            <Home/>
        }
      </div>
    )
  }
}

export default App;
