import React, { useState, useContext, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import info from '../assets/info.svg'
import Fade from 'react-reveal/Fade'
import { DateAndTime } from '../App';
import axios from 'axios'
import success from '../assets/success.svg'
import { Modal  } from 'reactstrap';

const UserInformation = (props) => {

  const { pathname } = useContext(DateAndTime);


  const [ channelId, setChannelId ] = useState([])
  const [ channelIdType, setChannelIdType] = useState([])
  const [ apointmentDetails, setAppointmentDetails ] = useState('')
  const [ load, setLoad ] = useState(false)

  useEffect(() => {
    axios.get(`https://meeting.perxclm.com/contact${pathname}/channels`)
    .then(res => {
      const meetingChannelData =  res.data.data
      const channelId = meetingChannelData.map( id => id.id )
      setChannelId(channelId)
      const channelIdType = meetingChannelData.map( id => id.channel_name )
      setChannelIdType(channelIdType)
    })
  }, [])

  const loading = () => {
    setLoad(!load)
  }

  // console.log('Channels Id ', channelId )
  // console.log('Channels Type ', channelIdType )

  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);



  const { selectedTime, slot_id } = useContext(DateAndTime)

  const [ data, setData ] = useState({
    firstName: '', 
    surname: '', 
    email: '', 
    mobileNumber: '', 
    companyName: '', 
    subject: '', 
    meetingType: '', 
    meetingLink: '',
  })

  let selectedChannel = data.meetingType
  // console.log('selected Channel', selectedChannel)

  let indexOfSelectedCh = channelIdType.indexOf(selectedChannel)
  // console.log('selected channel', indexOfSelectedCh)

  let selectedChannelId = channelId[indexOfSelectedCh]
  // console.log('selected channel id', selectedChannelId)


  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const meeting_type = data.meetingType
  console.log(meeting_type)

  console.log(data)

  const handleSubmit = (e) => {
    e.preventDefault()    

    axios.post(`https://meeting.perxclm.com/contact${pathname}/appointment/create`, {
      slot_id: slot_id,
      first_name: data.firstName,
      last_name: data.surname,
      email: data.email,
      company_name: data.companyName,
      subject: data.subject,
      meeting_channel_id: selectedChannelId,
      meeting_link: data.meetingLink,
      phone: data.mobileNumber
    })
      .then((res) => {
        setAppointmentDetails(res.data.data.appointment_date)
        setLoad(false)
        toggle()
        window.location = "https://www.loyaltysolutionsnigeria.com/";
        console.log(res)
      }).catch( err => {
        alert('Network Error') 
        setLoad(false)
        console.log('Error returned', err)
    })
  }


  if ( selectedTime.length === 0 ){
    return ''
  } else {
    return (
      <Fade up>
        <div className="userInfo">
          <h5>Enter information</h5>
          <p>Enter your infromation here</p>
          <form onSubmit={handleSubmit} >
            <div className="card-bodie">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group required-field">
                    <label>First Name <span class="required"></span></label>
                    <input type="text" className="form-control" name="firstName" onChange={handleChange} required />
                  </div>
                </div>
  
                <div className="col-md-6">
                  <div className="form-group required-field">
                      <label >Surname <span class="required"></span></label>
                      <input type="text" className="form-control" name="surname" onChange={handleChange} required />
                  </div>
                </div>
              </div>
  
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group required-field">
                    <label >Email Address <span class="required"></span></label>
                    <input type="email" className="form-control" name="email" onChange={handleChange} required />
                  </div>
                </div>
  
                <div className="col-md-6">
                  <div className="form-group required-field">
                      <label>Mobile Number <span class="required"></span></label>
                      <input type="number" className="form-control" name="mobileNumber" onChange={handleChange} required/>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group required-field">
                    <label>Company Name <span class="required"></span></label>
                    <input type="text" className="form-control"  name="companyName" onChange={handleChange} required />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group required-field">
                    <label>Subject <span class="required"></span></label>
                    <input type="text" className="form-control"  name="subject" onChange={handleChange} required />
                </div>
              </div>
              <div >
                <div className="col-md-12" >
                  <div className="required-field">
                    <label htmlFor="acc-name">Select preferred channel <span class="required"></span></label>
                    <select type="text" className="form-control" name="meetingType" onChange={handleChange} >
                      <option>Select Channel</option>
                      { channelIdType.map( channel => 
                        <option>{channel}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="col-md-12" >
                  <div className="required-field">
                    <div className="meeting-link">
                      <label htmlFor="acc-name">Meeting Link</label>
                      <label> <img src={info} alt="Info" style={{marginRight: ".4rem"}}/>Copy and paste your meeting link here</label>
                    </div>
                    <input type="url" className="form-control" name="meetingLink" onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="form-footer-right" onClick={loading}>
                <button type='submit' className="btn btn-primary" >
                  {load ? "Loading..." : "Confirm"}
                </button>
              </div>
            </div>
          </form>

          <div>
          <Modal isOpen={modal} toggle={toggle} className={className} style={{width: '80%'}} className="modal-main">
              <div style={{textAlign: "center"}} className='modalClass'>
                <img style={{width: '60px'}}src={success} alt="" />
                <h3 style={{paddingTop: "30px", fontSize: '16px',}}>Meeting Appointment</h3>
                <h3 style={{ fontSize: '16px',}}>Successful</h3>
                <h4 style={{ fontSize: '12px', marginTop: '20px', marginBottom: '0'}}>Appointment Details</h4>
                <p style={{ paddingTop:'0px', fontSize: '12px', marginTop: '5px'}}>{apointmentDetails}</p>
              </div>
          </Modal>
    </div>

        </div>
    </Fade>
    )
  }
}

export default UserInformation
