import React, { useContext } from 'react'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from '@material-ui/icons/Done';
import Fade from 'react-reveal/Fade'
import { DateAndTime } from '../App';



const useStyles = makeStyles(theme => ({
    defaultBg: {
        backgroundColor: "white",
        border: '1px solid #E8E8E8',
        color: '#0098CE',
        borderRadius: 5,
        fontSize: '.85rem',

        "&:hover": {
            backgroundColor: "#fff",
            cursor: "pointer"
        }
    },
    onClickBg: {
        backgroundColor: "#0098CE",
        border: 'none',
        borderRadius: 5,
        color: '#fff',
        fontSize: '.9rem',
        "&:hover": {
            backgroundColor: "#0098CE",
            cursor: "pointer"
        }
    }
  }));


const Time = ( ) => {

    const { times, selectedTime, setSelectedTime } = useContext(DateAndTime);

    const classes = useStyles();

    const toggleStatus = (id) => {
        console.log(id)
        setSelectedTime([id, ...selectedTime])
    }
    
    if( times === undefined ){
        return ''
    } else {
        return (
            <Fade up>
                <div className="time">
                    <h5>Choose a time</h5>
                    <p>GMT +1</p>
                    <div className="timeList">
                        { times.map( (time) => 
                            <Chip 
                                key= {time.id} 
                                label={time.start_time.slice(0,5)} 
                                className={ selectedTime[0] === time ? classes.onClickBg : classes.defaultBg }
                                icon={ selectedTime[0] === time ? <DoneIcon style={{ color: 'white' }} /> : "" }
                                onClick={ () => toggleStatus(time)}
                            />
                        )}
                    </div>
                </div>
            </Fade>
        )
    }
}

export default Time